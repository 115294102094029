export const selector = '.slideshow'

export function hydrate (element) {
  const slides = element.querySelectorAll('.slideshow__slide')
  const counter = element.querySelector('.slideshow__counter')
  const caption = element.querySelector('.slideshow__caption')
  const prev = element.querySelector('[data-action=prev]')
  const next = element.querySelector('[data-action=next]')

  show()

  if (slides.length <= 1) {
    prev.style.display = 'none'
    next.style.display = 'none'
  }

  prev.addEventListener('click', showPrev)
  next.addEventListener('click', showNext)
  window.addEventListener('keyup', handleKeyPress)

  function showPrev () {
    show((+(element.dataset.index || 0) + slides.length - 1) % slides.length)
  }

  function showNext () {
    show((+(element.dataset.index || 0) + 1) % slides.length)
  }

  function handleKeyPress (e) {
    console.log(e.key)
    if (e.key === 'ArrowLeft') showPrev()
    if (e.key === 'ArrowRight') showNext()
  }

  function show (index = 0) {
    element.dataset.index = index
    counter.innerHTML = index + 1
    caption.innerHTML = slides[index]?.querySelector('figcaption')?.innerHTML
    element.classList.add('has-started')

    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.toggle('is-visible', index === i)
    }
  }

  return {
    destroy: () => {
      window.removeEventListener('keyup', handleKeyPress)
    }
  }
}
