export const selector = '.article'

const overlap = (a, b) => !(
  ((a.y + a.height) < (b.y)) ||
  (a.y > (b.y + b.height))
)

export function hydrate (element) {
  const refs = element.querySelectorAll('a.footnote-ref')
  const figures = element.querySelectorAll('figure')

  update()
  const debounced = () => window.requestAnimationFrame(update)

  window.addEventListener('load', update)
  window.addEventListener('resize', debounced)

  function update () {
    let lastY = 0

    for (const figure of figures) {
      figure.classList.remove('is-overlapping-footnote')
    }

    for (const ref of refs) {
      const id = ((ref.href || '').match(/#(.*)/) || [])[1]
      const footnote = id && element.querySelector(`.footnotes [id='${id}']`)
      if (!footnote) continue

      const y = Math.max(lastY, ref.offsetTop)
      footnote.style.setProperty('--offset-top', y + 'px')
      lastY = y + footnote.clientHeight + 10

      // Mark overlapping figures
      for (const figure of figures) {
        if (overlap(figure.getBoundingClientRect(), footnote.getBoundingClientRect())) {
          figure.classList.add('is-overlapping-footnote')
        }
      }
    }
  }

  return {
    destroy: () => {
      window.removeEventListener('load', update)
      window.removeEventListener('resize', debounced)
    }
  }
}
