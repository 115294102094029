import Barba from '@barba/core'

export const selector = 'nav.menu'

export function hydrate (element) {
  const toggle = element.querySelector('input[type=checkbox]')
  const links = Array.from(element.querySelectorAll('a')).map(a => ({
    a,
    path: new URL(a.href).pathname
  }))

  Barba.hooks.afterLeave(() => {
    toggle.checked = false
  })

  Barba.hooks.enter((data) => {
    for (const { path, a } of links) {
      a.classList.toggle('is-active', data.next.url.path.startsWith(path))
    }
  })
}
