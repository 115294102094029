import BarbaView from 'abstractions/BarbaView'
import * as Article from 'components/Article'
import * as Slideshow from 'components/Slideshow'

export default new BarbaView('contribution-slideshow', {
  afterEnter: function ({ next }) {
    this.refs.article = Article.hydrate(document.querySelector(Article.selector))
    this.refs.slideshow = Slideshow.hydrate(document.querySelector(Slideshow.selector))
  }
})
